import Layout from "components/layout";
import SEO from "components/seo";
import Toronto from "components/locationIndexPages/Toronto";
import React from "react";

export default function index() {
  const title = "Translation Services Agency- in Toronto - Tomedes";
  const description =
    "Looking for translation services in Toronto? Tomedes provides translation, localization, and interpretation in over 120+ languages-- with rapid delivery, 24/7 support and 1-year guarantee.";
  const keywords = "Translation Services in Toronto";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/toronto-translation-services"
      />
      <Toronto />
    </Layout>
  );
}
